// eslint-disable-next-line
import React, {useState, useEffect} from 'react'
import './Contact.css'

function Contact(props) {
    return (
        <div className='Contact' >
            <div className="ContactTitleContainer">
                <h3 className="ContactTitle">Contact Me:</h3>
                <h3 className="ContactSummary">I'd love to hear from you!</h3>
            </div>
        </div>
    );
}

export default Contact;