import React from "react";
import "./main.css";

const PlaceHolder = () => {
    return (
        <div className='PlaceHolder'>
            <h1 className='ProjectNotFound'>Project not found!</h1>
            <p className='ProjectNotFoundHelpMessage'>Please check the URL and try again.</p>
        </div>
    );
};

export default PlaceHolder;