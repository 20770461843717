import React from 'react';
import ModelViewer from '../../../components/modelViewer/ModelViewer';

import "./main.css";

import insertBreaks from '../../../utils/insertBreaks'

import image0 from "./images/notebook/2021_03_06-0.png"      
import image1 from "./images/notebook/2021_07_16-0.png"     
import image2 from "./images/notebook/2021_07_16-1.png"    
import image3 from "./images/notebook/2021_07_17-0.png"     
import image4 from "./images/notebook/2021_07_17-1.png"     
import image5 from "./images/notebook/2021_07_17-2.png"
import image6 from "./images/notebook/2021_07_17-3.png"     
import image7 from "./images/notebook/2021_07_17-4.png"     
import image8 from "./images/notebook/2022_02_15-0.png"  
import image9 from "./images/notebook/2022_02_15-1.png"
import image10 from "./images/notebook/2022_03_17-0.png"
import image11 from "./images/notebook/2022_03_17-1.png"
import image12 from "./images/notebook/eqt_motion_dgfly.png"
import image13 from "./images/notebook/example.png"

const Images = [
    image0, image1, image2, image3, image4, 
    image5, image6, image7, image8, image9,
    image10, image11, image12, image13
]

const ImagesComp = Images.map((image, index) => {
    return (
        <div className='NotebookImage' key={index}>
            <img className='NotebookImage' src={image} alt={index} />
        </div>
    )
})

const ImageComponent = () => {
    return (
        <div className='ImageContainer'>
            {ImagesComp}
        </div>
    )
}


const DragonFly = () => {
    return (
        <div className='DragonFly'>
            <div className='ProjectTitleContainer'>
                <h3 className='ProjectTitle'>DragonFly</h3>
                <h3 className='ProjectSummary'>Advanced flight control software</h3>
            </div>
            <div className='ProjectContent'>
                <div className="DroneModel" >
                    <ModelViewer 
                        scale="4" 
                        modelPath={"/drone.glb"} 
                        position = {[0, -1, 0]}
                    />
                </div>
                <p align='left'>
                    The DragonFly project is a flight control software designed specifically for quadcopters. It is written in C++, a popular programming language known for its performance and efficiency. The software is intended to run on a Raspberry Pi 4B+ board, which is a versatile and widely used single-board computer.
                </p>
                <p align='left'>
                    With DragonFly, quadcopter enthusiasts can leverage its flight control capabilities to manage and control their aerial vehicles. The software likely provides features such as stabilization, motor control, sensor integration, and navigation algorithms. It may also offer interfaces for user input and telemetry data to facilitate communication and interaction with the quadcopter.
                </p>
                <p align='left'>
                    Given that DragonFly is written in C++ and targeted for the Raspberry Pi 4B+, it demonstrates a focus on performance and compatibility. The Raspberry Pi 4B+ offers sufficient processing power, memory, and I/O capabilities to handle the computational requirements of flight control software. C++ is a popular language for embedded systems and is known for its performance and efficiency. These design choices suggest that DragonFly is intended to be a high-performance flight control software.
                </p>
                <ImageComponent />
            </div>
        </div>
    );
};

export default DragonFly;