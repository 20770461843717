import React from "react";
import "./main.css";

const RizBert = () => {
    return (
        <div className='ProjectTitleContainer'>
            <h3 className='ProjectTitle'>RizBert</h3>
            <h3 className='ProjectSummary'>Home automation web app</h3>
        </div>
    );
};

export default RizBert;