import React from 'react';
import './Project.css'
import monthNames from '../../assets/constants'

import dragonflyThumbnail from '../../assets/projects/dragonfly/thumbnail.png'
import rizbertThumbnail from '../../assets/projects/rizbert/thumbnail.png'
import placeholderThumbnail from '../../assets/projects/placeholder/thumbnail.png'

const imageMap = { 'projects/dragonfly': dragonflyThumbnail,
                    'projects/rizbert': rizbertThumbnail,
                    'projects/placeholder': placeholderThumbnail
                }

function getDateString(startDate, endDate){
    var today = new Date()

    var start_str = monthNames[startDate.month].substring(0,3) + '. ' + startDate.year.toString() 
    var end_str = monthNames[endDate.month].substring(0,3)  + '. ' + endDate.year.toString()
    
    if (endDate.year === today.getFullYear().toString() && endDate.month === today.getMonth().toString()){
        end_str = 'Present'
    }

    return start_str + ' - ' + end_str
}

/**
 * 
 * @param {type} props.image - the link to the image
 * @param {type} props.title - the title of the project
 * @param {type} props.startDate - the start date of the project
 * @param {type} props.endDate - the end date of the project
 * @param {type} props.uid - the unique identifier of the project
 * @returns 
*/
const Project = (props) => {
    return (
        <div className='Project'>
            <img src={imageMap[props.image]} alt="thumbnail" className='ProjectImage'/>
            <h2 className='ProjectTitle'>{props.title}</h2>
            <p className='ProjectDate'>{getDateString(props.startDate, props.endDate)}</p>
        </div>
    );
}

export default Project;