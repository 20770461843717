// eslint-disable-next-line
import React from 'react'
import { useParams } from 'react-router-dom';
import './ProjectDetails.css'

import DragonFly from '../../assets/projects/dragonfly/main.js'
import RizBert from '../../assets/projects/rizbert/main.js'

import PlaceHolder from '../../assets/projects/placeholder/main.js'

const ProjectDetails = () => {
    const { projectId } = useParams();

    // Assuming you have a function to fetch project details based on the projectId
    const fetchProjectDetails = (projectId) => {
        switch (projectId) {    
            case 'dragonfly':
                return <DragonFly />;
            case 'rizbert':
                return <RizBert />;
            default:
                return <PlaceHolder />;
        }
    };

    const projectDetails = fetchProjectDetails(projectId);

    if (!projectDetails) {
        return <PlaceHolder />;
    }

    return (
        <div className='ProjectDetails'>
            {projectDetails}
        </div>
    );
}

export default ProjectDetails;